const en = {
  common: {
    i_loading: "Loading...",
    i_back: "Back",
    i_timedOut: "Request timed out, please check network",
    i_copy: "Copied",
    i_cancel: "Cancel",
    i_goBack: "Goback now",
    i_copyC: "Copy"
  },
  index: {
    i_coinsLog: "Coins record",
    i_totalCoins: "Total coins",
    i_convert: "Withdraw",
    i_convertMsg: "Coins for cash",
    i_inviteFriends: "Invite friends",
    i_inviteFriendsMsg: "Get more coins",
    i_inviteCode: "Code",
    i_accountC: "Account",
    i_account: "Account",
    i_leaderboard: "Ranking",
    i_contact: "Contact",
    i_browserOpen: "Copy to default Browser to open",
    i_language: "en"
  },
  convert: {
    i_convert: "Withdraw",
    i_convertLog: "Withdraw Record",
    i_convertT: "Convert your coins to cash",
    i_choose: "Please choose a redemption method",
    i_input: "Input",
    i_account: "Receiving account",
    i_editAccount: "Edit account",
    i_checkN: "No responsibility for wrong numbers",
    i_receivingName: "Receiving name",
    i_enterReceivingName: "Enter receiving name",
    i_select: "Select currency",
    i_currency: "Currency",
    i_pleaseSelect: "Please select",
    i_bindAccount: "Bind the Receiving account",
    i_enterReceivingAccount: "Enter receiving account",
    i_redeemNow: "Withdraw now",
    i_bindN: "After binding, it cannot be changed",
    i_bindNow: "Bind now",
    i_following: "The following",
    i_bindT:
      "The account is about to be bound to your account. It cannot be changed after binding. Are you sure?",
    i_bind: "Bind",
    i_changeNow: "Change now",
    i_changeT:
      "Modifications need to be reviewed by the administrator, and cannot be exchanged during the review period. Do you want to confirm the change?",
    i_newReceivingAccount: "New receiving account",
    i_modifyReceivingAccount: "Modify the receiving account",
    i_newReceivingName: "New receiving name",
    i_confirm: "Confirm",
    i_ok: "Ok",
    i_convertMsg: "Redemption has been submitted and is under review",
    i_info_ing: "Information under review",
    i_infoN: "Information review failed",
    i_tips: "Tips",
    i_activateMsg: "Please activate Premium Features",
    i_accountN: "Account cannot be empty",
    i_nameN: "Name cannot be empty",
    i_reviewSucceeded: "Review succeeded",
    i_underReview: "Under review",
    i_auditFailure: "Audit failure",
    i_invalid: "Invalid",
    i_remark: "Remark",
    i_checkConvertInfo: "Please confirm your info"
  },
  inviteFriend: {
    i_inviteFriend: "Invite friends",
    i_inviteT: "Every friend you invite",
    i_reward: "Reward",
    i_commissionT: "Friends' coins",
    i_total: "Total",
    i_friends: "Friends ",
    i_text1: "Friends download app via your link",
    i_text2: "Friends login/enter your code",
    i_text3_1: "Friends Play Game -> you get ",
    i_text3_2: " friends' coins",
    i_code: "Invitation code",
    i_inviteNow: "Invite now",
    i_enterCode: "Enter the code",
    i_friendCode: "Fill in the friend invite code",
    i_code1: "Only 1 invitation code can be filled in",
    i_claimNow: "Claim it now",
    i_inviteLog: "Invitation record",
    i_notActivated: "Premium features are not activated",
    i_filledIn: "Filled in",
    i_noLog: "No content yet",
    i_newest: "Newest",
    i_biggest: "Biggest",
    i_tipsDialogMsg:
      "Your friends need to download the app by clicking your link to display the Withdraw entrance !!!",
    i_youHaveBeenInvited: "You have been invited"
  },
  all: {
    i_initialTask: "Initial Task",
    i_download: "Download",
    i_downloadNo: "Download now",
    i_loginAccount1: "Open the game, Sign-In the same",
    i_loginAccount2: "Google account",
    i_loginAccount3: "",
    i_state: "State",
    i_completed: "Completed",
    i_undone: "Undone",
    i_claimRewards: "Claim Rewards",
    i_advancedTasks: "Advanced Tasks",
    i_claim: "Claim",
    i_allTasksCompleted: "All advanced tasks have been completed",
    i_inviteTasks: "Invite Tasks",
    i_inviteFriendsJoin: "Invite friends to join in",
    i_friends: "Friends",
    i_inviteFriendsTeam: "Invite friends join your team",
    i_earnRewards: "Earn rewards",
    i_playGamesCash: "Play games and earn cash",
    i_joinNow: "Join now",
    i_todayReward: "Today's",
    i_totalReward: "Total",
    i_currentTeam: "Current team",
    i_invitationCode: "Invite code",
    i_inviteFriendsJoinTeam: "Invite friends join your team",
    i_everyFriendInvited: "For every friend invited",
    i_currentFriends: "Current Friends",
    i_currentRewards: "Current rewards",
    i_friendsRewardsThem: "When your friends get rewards, you can get them",
    i_commissionUpTo: "commission, up to",
    i_friendsClickJoinTask: "Friends click on the link to join in the task",
    i_friendsCompleteInitialTask: 'Friends complete the "Initial Task"',
    i_joinTeam: "Successfully join your team",
    i_newestFriends: "Newest Friends",
    i_bestFriends: "Best Friends",
    i_commission: "Commission",
    i_variousGifts: "Various Gifts",
    i_redeemNow: "Redeem now",
    i_couponDetails: "Coupon details",
    i_rewardRecords: "Reward records",
    i_leaderboard: "Ranking",
    i_viewLeaderboard: "View Ranking",
    i_task: "Task",
    i_completeTasks: "Complete tasks to receive rewards",
    i_invite: "Invite",
    i_nickName: "Name",
    i_friendInvitesHisTeam:
      "Your friend invites you to join his team and receive cash! !",
    i_moreCash: "More cash! !",
    i_joinTask: "Join this task and receive",
    i_joinTeamNow: "Join the team now",
    i_playNow: "Play Now",
    i_useBrowser: "Please use the following browser",
    i_joined: "Joined",
    i_pleaseInitTask: 'Please complete the "Initial Tasks" first',
    i_awardRecord: "Award Record",
    i_join: "Join ",
    i_expTsaks: "EXP Tasks",
    i_copyToBrowser: "Copy to default Browser to open"
  },
  leaderboard: {
    i_totalCoins: "Total Coins",
    i_friends: "Friends"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default en;
