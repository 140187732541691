<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="invite-layout">
      <div style="padding: 8%;">
        <div style="background: linear-gradient(0deg, rgba(122, 119, 246, 1) 0%, rgba(122, 119, 246, 1) 1%, rgba(71, 67, 248, 1) 97.83%, rgba(71, 67, 248, 1) 100%);border-radius: 20px;padding: 8%;position: relative;">
          <div style="position: relative;">
            <span style="z-index: 1;position: absolute;box-sizing: border-box;top: 8%;left: 0;right: 0;font-size: 12px;">
              <img src="@/assets/images/invite/inviteBBG.png" style="width: 100%;" />
            </span>
            <div style="position: relative;z-index: 2;">
              <img :src="taskInfo.appIcon" style="width: 60px;vertical-align: middle;margin-bottom: 5%;border-radius: 12px;" />
              <div style="color: white;">{{$t('all.i_joinTask')}}</div>
              <div style="color: white;font-size: 32px;margin-top: 12%;">${{taskInfo.taskRewardNum}}</div>
              <el-button @click="goInviteTask()" style="width: 100%;border-radius: 15px;color: white;margin-top: 5%;border: none;font-weight: bold;background: linear-gradient(90deg, rgba(18, 26, 149, 1) 0%, rgba(18, 26, 149, 1) 1%, rgba(77, 90, 236, 1) 98.1%, rgba(77, 90, 236, 1) 100%);">
                {{$t('all.i_joinTeamNow')}}
              </el-button>
            </div>
          </div>
        </div>
        <div style="margin-top: 18%;">
          <img src="@/assets/common/appsGiftIcon.png" style="width: 60px;vertical-align: middle;margin-right: 10px;" />
        </div>
        <div style="vertical-align: middle;font-size: 24px;color: rgba(122, 119, 246, 1);">“Apps Gift”</div>
        <div style="text-align: left;margin-top: 4%;line-height: 30px;color: white;">
          {{$t('all.i_friendInvitesHisTeam')}}
          <br />
          {{$t('all.i_moreCash')}}
          {{$t('all.i_moreCash')}}
          <div>
            <img src="@/assets/images/invite/giftCardImg.png" style="width: 88%;margin-top: 10px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      inviteCode: "",
      taskInfo: {}
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color: rgba(35, 22, 66, 1);");
  },
  beforeDestroy() {
    document
      .querySelector("body")
      .removeAttribute("style", "background-color: rgba(35, 22, 66, 1);");
  },
  methods: {
    goInvite() {
      this.isLoading = true;
      if (this.$route.query.tid != null && this.$route.query.tid != "") {
        this.$axios({
          url: "/webCash/invite",
          method: "get",
          params: {
            tid: this.$route.query.tid
          }
        }).then((result) => {
          if (result.data.code == 1) {
            this.isLoading = false;
            this.taskInfo = result.data.data.taskInfo;
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
            this.$router.push("/index");
          }
        });
      }
    },
    goInviteTask() {
      let token = localStorage.getItem("token");
      if (token) {
        this.$router.push({
          path: "/taskInfo",
          query: {
            tid: this.$route.query.tid,
            referrer: this.$route.query.referrer
          }
        });
      } else {
        this.$router.push({
          path: "/login",
          query: {
            tid: this.$route.query.tid,
            referrer: this.$route.query.referrer
          }
        });
      }
    }
  },
  created() {
    this.goInvite();
    this.inviteCode = this.$route.query.referrer;
  }
};
</script>

<style>
#invite-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  background-color: rgba(35, 22, 66, 1);
}
</style>
