const zh = {
  common: {
    i_loading: "拼命加载中。。。",
    i_back: "返回",
    i_timedOut: "请求超时，请检查网络",
    i_copy: "复制成功",
    i_cancel: "取消",
    i_goBack: "立即返回",
    i_copyC: "复制"
  },
  index: {
    i_coinsLog: "金币记录",
    i_totalCoins: "你已积累的财富",
    i_convert: "兑换",
    i_convertMsg: "金币换现金",
    i_inviteFriends: "邀请好友",
    i_inviteFriendsMsg: "领取更多金币",
    i_inviteCode: "邀请码",
    i_accountC: "账户中心",
    i_account: "账号",
    i_leaderboard: "排行榜",
    i_contact: "联系客服",
    i_browserOpen: "请拷贝至默认浏览器打开",
    i_language: "zh"
  },
  convert: {
    i_convert: "兑换",
    i_convertLog: "兑换记录",
    i_convertT: "将你的金币换成现金",
    i_choose: "请选择兑换方式",
    i_input: "输入",
    i_account: "收款账号",
    i_editAccount: "修改账号",
    i_checkN: "输错号码概不负责",
    i_receivingName: "收款姓名",
    i_enterReceivingName: "输入收款姓名",
    i_select: "选择兑换货币",
    i_currency: "兑换货币",
    i_pleaseSelect: "请选择",
    i_bindAccount: "绑定收款账号",
    i_enterReceivingAccount: "输入收款账号",
    i_redeemNow: "立即兑换",
    i_bindN: "绑定后不可更改",
    i_bindNow: "立即绑定",
    i_following: "以下",
    i_bindT: "账户即将绑定为你的收款账户，绑定后不可更改，确定绑定吗？",
    i_bind: "确定绑定",
    i_changeNow: "立即更改",
    i_changeT:
      "申请修改需要等待管理员审核，审核期间将不能进行兑换，确认更改吗？",
    i_newReceivingAccount: "新的收款账号",
    i_modifyReceivingAccount: "修改收款账号",
    i_newReceivingName: "新的收款姓名",
    i_confirm: "确定更改",
    i_ok: "好的",
    i_convertMsg: "兑换申请已经提交，正在审核中",
    i_info_ing: "信息审核中",
    i_infoN: "信息审核失败",
    i_tips: "提示",
    i_activateMsg: "请先激活高级功能",
    i_accountN: "账号不能为空",
    i_nameN: "姓名不能为空",
    i_reviewSucceeded: "审核成功",
    i_underReview: "审核中",
    i_auditFailure: "审核失败",
    i_invalid: "无效",
    i_remark: "备注",
    i_checkConvertInfo: "请确认你的兑换信息"
  },
  inviteFriend: {
    i_inviteFriend: "邀请好友",
    i_inviteT: "每邀请一个好友",
    i_reward: "奖励",
    i_commissionT: "好友的提成",
    i_total: "总共",
    i_friends: "好友",
    i_text1: "好友通过你的链接下载APP",
    i_text2: "好友在APP内登陆/输入你的邀请码",
    i_text3_1: "好友玩游戏 -> 你获得",
    i_text3_2: "好友的金币",
    i_code: "邀请码",
    i_inviteNow: "立即邀请",
    i_enterCode: "填写邀请码",
    i_friendCode: "填写好友邀请码",
    i_code1: "只能填写1位好友邀请码",
    i_claimNow: "立即领取",
    i_inviteLog: "邀请记录",
    i_notActivated: "未升级宝箱",
    i_filledIn: "已填写",
    i_noLog: "暂无内容",
    i_newest: "最新",
    i_biggest: "最大",
    i_tipsDialogMsg: "你的好友需要通过点击你的链接下载app才能显示兑换入口 !!!",
    i_youHaveBeenInvited: "你已经被邀请了"
  },
  all: {
    i_initialTask: "初始任务",
    i_download: "下载",
    i_downloadNo: "立即下载",
    i_loginAccount1: "打开游戏，登陆同一个",
    i_loginAccount2: "Google账号",
    i_loginAccount3: "",
    i_state: "状态",
    i_completed: "已完成",
    i_undone: "未完成",
    i_claimRewards: "领取奖励",
    i_advancedTasks: "高级任务",
    i_claim: "领取",
    i_allTasksCompleted: "高级任务已经全部完成",
    i_inviteTasks: "邀请任务",
    i_inviteFriendsJoin: "邀请好友一起参与",
    i_friends: "好友",
    i_inviteFriendsTeam: "邀请好友加入队伍",
    i_earnRewards: "获得奖励",
    i_playGamesCash: "玩游戏赚钱",
    i_joinNow: "立刻加入",
    i_todayReward: "今日奖励",
    i_totalReward: "历史奖励",
    i_currentTeam: "当前队伍",
    i_invitationCode: "邀请码",
    i_inviteFriendsJoinTeam: "邀请好友加入你的队伍",
    i_everyFriendInvited: "每一位好友",
    i_currentFriends: "当前好友",
    i_currentRewards: "当前奖励",
    i_friendsRewardsThem: "当你的好友获得奖励时，你能够获得他们",
    i_commissionUpTo: "的提成，最高",
    i_friendsClickJoinTask: "好友点击链接参加任务",
    i_friendsCompleteInitialTask: "好友完成初始任务",
    i_joinTeam: "成功加入团队",
    i_newestFriends: "最新成员",
    i_bestFriends: "最佳成员",
    i_commission: "提成",
    i_variousGifts: "超多品类",
    i_redeemNow: "立即兑换",
    i_couponDetails: "收支明细",
    i_rewardRecords: "奖励记录",
    i_leaderboard: "排行榜",
    i_viewLeaderboard: "观看排行榜",
    i_task: "任务",
    i_completeTasks: "完成任务领取奖励",
    i_invite: "邀请",
    i_nickName: "昵称",
    i_friendInvitesHisTeam: "你的好友邀请你加入他的队伍，参与领取现金！！",
    i_moreCash: "更多现金！！",
    i_joinTask: "参与该任务领取",
    i_joinTeamNow: "立即加入队伍",
    i_playNow: "开始任务",
    i_useBrowser: "请使用以下浏览器打开",
    i_joined: "参与",
    i_pleaseInitTask: "请先完成初始任务",
    i_awardRecord: "奖励记录",
    i_join: "加入",
    i_expTsaks: "EXP任务",
    i_copyToBrowser: "复制到默认浏览器打开"
  },
  leaderboard: {
    i_totalCoins: "总金币",
    i_friends: "好友"
  },
  language: {
    zh: "中文",
    en: "English"
  }
};
export default zh;
