import Vue from "vue";
import VueRouter from "vue-router";
import Convert from "@/views/Convert.vue";
import ConvertLog from "@/views/ConvertLog.vue";
import CashLog from "@/views/CashLog.vue";
import Index from "@/views/Index.vue";
import InviteFriend from "@/views/InviteFriend.vue";
import ConvertInfo from "@/views/ConvertInfo.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import Login from "@/views/Login.vue";
import TaskInfo from "@/views/TaskInfo.vue";
import Invite from "@/views/invite/Invite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index"
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/taskInfo",
    name: "taskInfo",
    component: TaskInfo
  },
  {
    path: "/invite",
    name: "invite",
    component: Invite
  },
  {
    path: "/convert",
    name: "convert",
    component: Convert
  },
  {
    path: "/convertLog",
    name: "convertLog",
    component: ConvertLog
  },
  {
    path: "/cashLog",
    name: "cashLog",
    component: CashLog
  },
  {
    path: "/index",
    name: "index",
    component: Index
  },
  {
    path: "/inviteFriend",
    name: "inviteFriend",
    component: InviteFriend
  },
  {
    path: "/convertInfo/:id",
    name: "convertInfo",
    props: true,
    component: ConvertInfo
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: ErrorPage
  }
];

// 修复跳转页面，页面不置顶的问题
const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  routes,
  scrollBehavior
});

// router.beforeEach((to, from, next) => {
//   if (to.fullPath.indexOf("access_token") != -1) {
//     const urlQueryParams = to.fullPath.split("/")[1];
//     const keyValueQueryParams = urlQueryParams.split("&");
//     const queryParams = {};
//     for (var i = 0; i < keyValueQueryParams.length; i++) {
//       // 循环分割挂过后的地址
//       var item = keyValueQueryParams[i].split("=");
//       var key = item[0];
//       var value = item[1];
//       queryParams[key] = value;
//     }
//     if (queryParams.state != 1) {
//       const keyValueStateParams = queryParams.state.split("-");
//       for (var i = 0; i < keyValueStateParams.length; i++) {
//         // 循环分割挂过后的地址
//         var item = keyValueStateParams[i].split(":");
//         var key = item[0];
//         var value = item[1];
//         queryParams[key] = value;
//       }
//     }
//     axios({
//       url: "/webCash/user/login",
//       method: "post",
//       params: {
//         accessToken: queryParams.access_token
//       }
//     }).then((result) => {
//       localStorage.setItem("token", result.data.data.token);
//       if (queryParams.tid != null && queryParams.referrer != null) {
//         router.push({
//           path: "/index",
//           query: {
//             tid: queryParams.tid,
//             referrer: queryParams.referrer
//           }
//         });
//       } else {
//         router.push({ path: "/index" });
//       }
//     });
//   }
//   next();
// });

export default router;
