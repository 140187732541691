<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="convert-log-layout">
      <div class="cl-page-header">
        <img src="@/assets/common/leftArrow.png" @click="$router.push('/convert')" style="float: left;width: 24px;margin-top: 12px;margin-right: 10px;margin-bottom: 4px;" />
        <div style="float: right;">
          <div style="background-color: rgba(245, 243, 254, 1);padding: 3px 7px 4px 7px;border-radius: 20px;margin: 6px 8px 0 0;display: inline-block;">
            <span style="font-size: 14px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
              {{ $store.state.cashNum }}
            </span>
            <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
          </div>
        </div>
      </div>

      <p style="border: 1px solid rgba(240, 240, 240, 1);padding: 0;margin: 3% 0;"></p>

      <div style="margin-top: 5%;margin-left: 5%;text-align: left;font-size: 12px;">*Credited in 3 ~15 Days, please be patient!</div>

      <div style="margin-top: 5%;margin-left: 5%;margin-right: 5%;overflow-y: scroll;height: 83vh;">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <div v-for="log in convertLog" :key="log.id" style="background-color: rgba(222, 231, 233, 1);margin-bottom: 3%;padding: 4%;border-radius: 8px;">
            <div style="overflow: hidden;">
              <div style="float: left;font-size: 22px;color: black;">
                {{log.price}}
              </div>
              <div style="float: right;">
                <span v-if="log.status == 1" style="color: black;">
                  {{$t('convert.i_reviewSucceeded')}}
                </span>
                <span v-else-if="log.status == 0" style="color: rgba(134, 122, 253, 1);">
                  {{$t('convert.i_underReview')}}
                </span>
                <span v-else-if="log.status == 2" style="color: red;">
                  {{$t('convert.i_auditFailure')}}
                </span>
                <span v-else-if="log.status == 3" style="color: red;">
                  {{$t('convert.i_invalid')}}
                </span>
              </div>
            </div>
            <div style="overflow: hidden;font-size: 13px;color: black;">
              <div style="float: left;margin-top: 2%;">
                {{log.cardName}}
              </div>
              <div style="float: right;margin-top: 2%;">
                {{log.time}}
              </div>
            </div>
            <div v-if="log.status == 2||log.status == 3" style="margin-top: 2%;text-align: left;font-size: 12px;color: red;">
              {{$t('convert.i_remark')}}：{{log.remark}}
            </div>
          </div>
        </div>

        <div v-if="convertLog==''">
          <img src="@/assets/common/noIcon.png" style="margin-top: 30%;width: 150px;" />
          <div style="margin-top: 5%;color: rgba(161, 180, 189, 1);">{{$t('inviteFriend.i_noLog')}}</div>
        </div>
      </div>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ convertLogParam.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      checkDialog: false,
      convertLog: [],
      busy: false,
      pageNum: 0,
      isLast: false,
      convertLogParam: {
        check: "",
        failMessage: "",
        appScheme: ""
      }
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    go() {
      if (this.convertLogParam.check == "login") {
        window.location.href = this.convertLogParam.appScheme + "login";
      } else {
        window.location.href = this.convertLogParam.appScheme;
      }
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.busy = true;
        this.pageNum++;

        this.isLoading = true;
        this.$axios({
          url: "/webCash/convertLog",
          method: "get",
          params: {
            pageNum: this.pageNum,
            recordType: -1
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            if (this.pageNum == 1) {
              this.userInfo = result.data.data.userInfo;
              this.$store.state.cashNum = result.data.data.userInfo.cashNum;
            }
            result.data.data.log.forEach((element) => {
              this.convertLog.push(element);
            });
            this.isLast = result.data.data.isLast;
          }
        });
        this.busy = false;
      }
    }
  },
  created() {
    this.isLoading = true;
  }
};
</script>

<style>
#convert-log-layout {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
}
#convert-log-layout .cl-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}
</style>
